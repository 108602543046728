// Apply active state to current section

(function( $ ) {

	const $card = $('.card-col');

	$(window).on('load resize', function() {
		if ( $card.length ) {

			// remove any previously set widths
			$card.css('max-width', '');

			var $card_smallest = $card.first();
			var new_width = $card_smallest.width();

			$card.each( function() {
				if ( $(this).width() < $card_smallest.width() ) {
					$card_smallest = $(this);
					new_width = $card_smallest.width();
				}
			});

			$card.each( function() {
				if ( $(this).width() != new_width ) {
					$(this).css('max-width', ( new_width + 40 ) + 'px');
				}
			});

		}
	});
	
})( jQuery );
