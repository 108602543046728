( function( $, window, document, undefined ) {

	Mmenu.configs.classNames.selected = "active";
	Mmenu.configs.offCanvas.page.selector = "#page";


	var $menu_panel = $('#js-site-nav');
	var $signup_panel = $('#sign-up');
	var $hamburger = $('.js-site-nav-toggle');
	//var $signup_toggle = $('.sign-up-toggle');

	var transition_duration = 400;

	document.addEventListener(
	    "DOMContentLoaded", () => {
	        const menu = new Mmenu( "#js-site-nav", {
	        		'keyboardNavigation': {
	        			enable: 'true'
	        		},
	        		'navbar': [
	        			{
	        				"title": ''
	        			}
	        		],
		            'extensions': [
			            "position-right",
			            "pagedim-black",
			            "fx-menu-slide",
	                  	"fx-panels-slide-100"
		            ]
				}
	        );

	        const signup = new Mmenu( "#sign-up", {
	        		'navbar': [
	        			{
	        				"title": ''
	        			}
	        		],
		            'extensions': [
			            "position-right",
			            // "pagedim-black",
			            "fx-menu-slide",
	                  	"fx-panels-slide-100",
	                  	"fullscreen"
		            ]
				}
	        );

	        const api = menu.API;
	        const s_api = signup.API;

	        /* jshint unused: false */
	        api.bind( "open:before",
	            ( panel ) => {
	            	if ( !$hamburger.hasClass('is-active') ) {
	                	$hamburger.addClass('is-active');
	            	}
	            }
	        );

	        api.bind( "close:before",
	            ( panel ) => {
	            	if ( $hamburger.hasClass('is-active') ) {
	            		$hamburger.removeClass('is-active');
	            	}
	            }
	        );

	        // s_api.bind( "open:before",
	        //     ( panel ) => {
	        //     	$signup_toggle.text('Close');
	        //     	if ( !$signup_toggle.hasClass('is-open') ) {
	        //         	$signup_toggle.addClass('is-open');
	        //     	}
	        //     }
	        // );

	        // s_api.bind( "close:after",
	        //     ( panel ) => {
		    //         $signup_toggle.text('Sign Up');
		    //         if ( $signup_toggle.hasClass('is-open') ) {
	        //     		$signup_toggle.removeClass('is-open');
	        //     	}
	        //     }
	        // );

	        $hamburger.on('click', function() {
				if ( $menu_panel.hasClass('mm-menu_opened') ) {
					menu.close();
					$hamburger.removeClass('is-active');
				} else if ( $signup_panel.hasClass('mm-menu_opened') ) {
					signup.close();
					setTimeout(function(){ 
						menu.open();
					}, transition_duration);
				} else {
					menu.open();
					$hamburger.addClass('is-active');
				}
			});

			// $signup_toggle.on('click', function() {

			// 	if ( $signup_panel.hasClass('mm-menu_opened') ) {
			// 		signup.close();
			// 	} else if ( $menu_panel.hasClass('mm-menu_opened') ) {
			// 		menu.close();
			// 		setTimeout(function(){ 
			// 			signup.open();
			// 		}, transition_duration);
			// 	} else {
			// 		signup.open();
			// 	}
			// });

	    }
	);

	$su_inner = $('body.single').find('#sign-up .sign-up__inner');

	$(window).on('load', function() {
		$su_inner.remove();
	});
	
} )( jQuery, window, document );
