( function( $, window, document, undefined ) {

	const $rellax_ele = $('.rellax');
	var $detect_mobile = $('.detect-mobile');

	if ( $rellax_ele.length ) {
		var rellax = new Rellax('.rellax', {
			breakpoints: [768, 992, 1201],
			center: true
		});

		$(window).on('load resize', throttle( controlRellax, 150) );
	}

	function controlRellax() {
		var mobile = $detect_mobile.css('visibility');

		if ( mobile == 'visible' && !$detect_mobile.hasClass('rellax-disabled') ) {
			rellax.destroy();
			$detect_mobile.addClass('rellax-disabled');
		} 
		else if ( mobile != 'visible' && $detect_mobile.hasClass('rellax-disabled') ) {
			rellax.refresh();
			$detect_mobile.removeClass('rellax-disabled');
		}
	}
	
} )( jQuery, window, document );