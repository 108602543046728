( function( $, window, document, undefined ) {

	const $accordion = $('.accordion-wrapper');
	const $accordionHeader = $('.accordion__header');
	const $accordionContent = $('.accordion__content');

	if ( $accordion.length ) {

		$accordionContent.each( function() {
			$(this).hide();
			$(this).attr('aria-hidden', 'true');
		});

		$accordionHeader.each( function() {
			$(this).find('h4').wrapInner( '<button class=\"js-accordion-toggle highlight\" aria-expanded=\"false\"><span></span></button>' );
		});

		$accordionHeader.on('click', function() {

			$(this).toggleClass('opened');

			$content = $(this).next($accordionContent);
			$button = $(this).find('.js-accordion-toggle');

			// Invert a string based boolean attribute 'true' <-> 'false'
			const invert_attr = (index, attr) => (attr == 'true' ? 'false' : 'true');

			$content
				.stop()
				.slideToggle(350)
				.attr('aria-hidden', invert_attr);

		   	$button.attr('aria-expanded', invert_attr);

		});

	}

} )( jQuery, window, document );
