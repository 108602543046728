( function( $, window, document, undefined ) {

	const $timeline_container = $('.timeline');
	const $timeline = $('.timeline__slider');
	const $timeline_content = $('.timeline__content-slider');
	const $timeline_prev = $timeline_container.find('.slick-arrow--prev');
	const $timeline_next = $timeline_container.find('.slick-arrow--next');

	if ( $timeline.length ) {

		$timeline.slick({
			slidesToShow: 5,
			slidesToScroll: 1,
			arrows: true,
			prevArrow: $timeline_prev,
			nextArrow: $timeline_next,
			centerMode: true,
			infinite: false,
			draggable: true,
			asNavFor: $timeline_content
		});

		$timeline_content.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			arrows: false,
			dots: false,
			infinite: false,
			asNavFor: $timeline
		});

	}

	$('.timeline__slider .slick-slide').on('click', function() {

		var slideIndex = $(this).data('slick-index');
		$timeline.slick('slickGoTo', slideIndex, false);

	});

	const $statistics = $('.statistics');
	const $statistics_slider = $('.statistics__slider');
	const $statistics_prev = $statistics.find('.slick-arrow--prev');
	const $statistics_next = $statistics.find('.slick-arrow--next');

	if ( $statistics_slider.length ) {

		$statistics_slider.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			prevArrow: $statistics_prev,
			nextArrow: $statistics_next,
			infinite: false,
			draggable: true,
			speed: 300
		});

	}

	// Slick Bar

	var carousels = $('.js-slick__carousel');

	carousels.on('beforeChange', function(event, slick, currentSlide, nextSlide){

		var $carousel = slick.$slider;
		var $parent = $carousel.closest('.js-slick');

		var $marker = $parent.find('.slick-bar__marker');
	  	var $target = $parent.find('.slick-bar').find('span[data-bar-index="' + nextSlide + '"]');
	  	var left = $target.css('left');
	  	var slideCount = $carousel.slick('getSlick').slideCount;
	  	var $next = $parent.find('.slick-arrow--next');
	  	var $prev = $parent.find('.slick-arrow--prev');

	  	console.log($prev);

	  	$marker.css('left', left);

	  	if ( nextSlide == 0 && !$prev.is(':disabled') ) {
			$prev.prop('disabled', true);
		} else if ( $prev.is(':disabled') ) {
			$prev.prop('disabled', false);
		}

		if ( nextSlide == (slideCount - 1) && !$timeline_next.is(':disabled') ) {
			$next.prop('disabled', true);
		} else if ( $next.is(':disabled') ) {
			$next.prop('disabled', false);
		}

	});

} )( jQuery, window, document );