( function( $, window, document, undefined ) {

	const $menu_label = $('.hamburger-container > span');

	var idleTimer = null;
	var idleState = true;
	var idleWait = 4000;
    
    $(window).on('scroll', function () {
    
        clearTimeout(idleTimer);
                
        if (idleState == true) { 
            
            // Reactivated event
            if ( ! $menu_label.hasClass('fade') ) {
				$menu_label.addClass('fade');
			}        
        }
        
        idleState = false;
        
        idleTimer = setTimeout(function () { 
            
            // Idle Event
            if ( $menu_label.hasClass('fade') ) {
				$menu_label.removeClass('fade');
			}

            idleState = true; }, idleWait);
    });
    
    $("body").trigger("mousemove");
    
	
} )( jQuery, window, document );