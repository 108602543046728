( function( $, window, document, undefined ) {

	var glide = new Glide('.glide', {
		startAt: 0,
		perView: 1,
		animationDuration: 750,
		animationTimingFunc: 'ease',
		gap: 100,
		rewind: false,
		peek: {
		  before: 0,
		  after: 500
		},
		breakpoints: {
		    1024: {
		    	peek: {
		    		before: 0,
		    		after: 300
		    	}
		    },
		    991: {
		    	peek: {
		    		before: 0,
		    		after: 0
		    	}
		    }
		}
	});

	const $carousel = $('.overlay-carousel');
	const $current = $carousel.find('span.current');
	const $slides = $carousel.find('.glide__slide');
	const $left_arrow = $carousel.find('.glide__arrow--left');
	const $right_arrow = $carousel.find('.glide__arrow--right');
	const $glide_text = $carousel.find('.glide__text');
	const $text_slides = $carousel.find('.text__slide');

	glide.on('run', function() {
		var currentIndex = glide.index + 1;
		var currentOverlay = $glide_text.find('[data-slide="' + currentIndex + '"]');

		var slidesLength = $slides.length;
		$current.text(currentIndex);

		$text_slides.each( function() {
			$(this).removeClass('active');
		})

		if ( currentOverlay.length ) {
			currentOverlay.addClass('active');
		}

		if ( currentIndex == 1 && !$left_arrow.is(':disabled') ) {
			$left_arrow.prop('disabled', true);
		} else if ( $left_arrow.is(':disabled') ) {
			$left_arrow.prop('disabled', false);
		}

		if ( currentIndex == slidesLength && !$right_arrow.is(':disabled') ) {
			$right_arrow.prop('disabled', true);
		} else if ( $right_arrow.is(':disabled') ) {
			$right_arrow.prop('disabled', false);
		}

	});

	function siblings (node) {
	  if (node && node.parentNode) {
	    let n = node.parentNode.firstChild
	    let matched = []

	    for (; n; n = n.nextSibling) {
	      if (n.nodeType === 1 && n !== node) {
	        matched.push(n)
	      }
	    }

	    return matched
	  }

	  return []
	}

	const CustomActiveClass = function (Glide, Components, Events) {
	    var Component = {
	        mount() {
	            this.changeActiveSlide();
	        },

	        changeActiveSlide() {
	            let slide = Components.Html.slides[Glide.index];
	            slide.classList.remove('is-next', 'is-prev');
	            slide.classList.add('is-active');
	 
	            siblings(slide).forEach((sibling) => {
	                sibling.classList.remove('is-active', 'is-next', 'is-prev');
	            });

	            if(slide.nextElementSibling) {
	                slide.nextElementSibling.classList.add('is-next');
	            }

	            if(slide.previousElementSibling) {
	                slide.previousElementSibling.classList.add('is-prev');
	            }
	        },
	    };

	    Events.on('run', () => {
	        Component.changeActiveSlide();
	    });

	    return Component;
	};

	if ( $carousel.length ) {
		glide.mount({
		    'CustomActiveClass': CustomActiveClass,
		});
	}

} )( jQuery, window, document );