( function( $, window, document, undefined ) {

	const $page_nav = $('.js-page-nav');

	$(window).on('load scroll resize', stickyNav );

	function stickyNav() {
		if ($(document).scrollTop() > 45 && !$page_nav.hasClass('Fixed') ) {
			$page_nav.addClass('Fixed');
		} else if ( $(document).scrollTop() <= 45 ) {
			$page_nav.removeClass('Fixed');
		}
	}
	
} )( jQuery, window, document );