/**
 * Detect scroll speed and direction, i.e. velocity, 
 * and take some action based on it.
 */
( function( $, window, document, undefined ) {

	let noInteractionTimer = null;
	let media = 'desktop';

	const $body = $('body'); // To avoid reselecting each time
	const isHome = $body.hasClass('home');

	const	hiddenClass = 'site-header-is-hidden';
	const $siteHeader = $('.site-header');
	const $sectionMenu = $('.section-menu');

	let headerHeight = $siteHeader.outerHeight();
	let heroThreshold = $(window).height() + $(window).width() * 0.1;

	const header = ( function() {
		let isShown = !$body.hasClass( hiddenClass );

		/**
		 * Get a transform based on the intent of the action.
		 * @param  {Boolean} show True to get the show transform, false to get the hide transform.
		 * @return {String}
		 */
		function getTransform( show = undefined ) {
			if ( undefined === show ) {
				show = isShown;
			}

			const translate = show ? headerHeight : 0;
			return `translateY(${translate}px)`;
		}
		
		/**
		 * Show the header & menu.
		 */
		function show() {
			$body.removeClass( hiddenClass );

			if ( $sectionMenu.length ) {
				$sectionMenu.css( {
					transform: getTransform( true )
				} );
			}

			isShown = true;
			// Store for 3 hours => 0.125 days
			cookie( 'pdb_header_is_shown', isShown ? 1 : 0, 0.125 );
		}

		/**
		 * Hide the header & menu.
		 */
		function hide() {
			$body.addClass( hiddenClass );

			if ( $sectionMenu.length ) {
				$sectionMenu.css( {
					transform: getTransform( false )
				} );
			}

			isShown = false;
			// Store for 3 hours => 0.125 days
			cookie( 'pdb_header_is_shown', isShown ? 1 : 0, 0.125 );
		}

		/**
		 * Set the state of the header based on a passed variable.
		 * @param  {Boolean} shouldShow true to show, false to hide.
		 */
		function setState( shouldShow ) {
			if ( shouldShow ) {
				show();
			} else {
				hide();
			}
		}

		/**
		 * Check if the header should change state or should be prevented.
		 * @return {Boolean} 
		 */
		function shouldChangeState() {
			return (
				!$body.hasClass('menu-is-open') && 
				!$body.hasClass('menu-is-opening') &&
				!$body.hasClass('menu-is-closing') &&
				!$body.hasClass('featherlight-open')
			);
		}

		return {
			getTransform,
			show,
			hide,
			setState,
			shouldChangeState
		};
	} )();

	function setMedia() {
		// Temporarily disable scrollbar
	  $('body').css( 'overflow', 'hidden' );

	  // Get window width
	  const width = $(window).width();
	  // Remove styles
	  $('body').css( 'overflow', '' );

		if ( width > 1150 ) {
			media = 'desktop';
		} else {
			media = 'mobile';
		}
	}

	setMedia();

	if ( 'mobile' == media ) {
		header.show();
	}
	
	let ScrollVelocity = class ScrollVelocity {

		constructor() {
			let _ = this;

			_.options = {
				/**
				 * The velocity, in pixels per second, to scroll down 
				 * before triggering a state change.
				 * Defaults to 0, triggering straight away.
				 * @type {Number}
				 */
				downThreshold: 0,

				/**
				 * The velocity, in pixels per second, to scroll up 
				 * before triggering a state change.
				 * @type {Number}
				 */
				upThreshold: 4,

				/**
				 * The amount of pixels from the top of the page,
				 * that must be scrolled before a change is triggered.
				 * 
				 * When scrolling up a state change will be 
				 * triggered once this minimum point is hit.
				 * @type {Number}
				 */
				minY: isHome && 'desktop' == media ? heroThreshold : 25,
			};

			// Class private properties
			_._scrollDelta = 0;
			_._scrollTop = 0;
			_._pastMinY = false;

			_._isDisabled = false;

			_._shadeHidden = false;
			_._scrollStoppedTimer = null;

			// Run an initial on scroll check
			if ( $(window).scrollTop() > 0 ) {
				_._onScroll();
			}

			_._bindEvents();
		}

		_bindEvents() {
			const _ = this;

			$(window).on( 'resize', debounce( (event) => {
				setMedia();

				_.options.minY = ( isHome && 'desktop' == media ) ? heroThreshold : 25;
			}, 150 ) );

			$(window).on( 'scroll', (event) => {
				// Need access to class as 'this' variable
				// so using anon function to call
				_._onScroll( event );
			} );

			return _;
		}

		enable() {
			const _ = this;
			_._isDisabled = false;
		}

		disable() {
			const _ = this;
			_._isDisabled = true;
		}

		_onScroll( event ) {
			const _ = this;

			if ( noInteractionTimer ) {
				clearTimeout( noInteractionTimer );
				noInteractionTimer = null;
			}

			if ( _._scrollStoppedTimer ) {
				clearTimeout( _._scrollStoppedTimer );
				_._scrollStoppedTimer = null;
			}

			if ( _._isDisabled ) {
				return;
			}

			// Set whether past threshold
			let scrollTop = $(window).scrollTop();
			_._pastMinY = scrollTop >= _.options.minY;

			if ( !_._shadeHidden && scrollTop > 65 ) {
				_._shadeHidden = true;
			}

			// Determine scroll delta
			// > 0 for scrolling down, < 0 for scrolling up
			_._scrollDelta = scrollTop - _._scrollTop;

			let absScrollDelta = Math.abs( _._scrollDelta );

			if ( isHome && _._shadeHidden && scrollTop < 60 && 'desktop' == media ) {
				header.hide();
			} else if (
				( _._scrollDelta > 0 && absScrollDelta > _.options.downThreshold ) || 
				( _._scrollDelta < 0 && absScrollDelta > _.options.upThreshold )
			) {
				$(window).trigger( 'scrollvelocitychange', [ _._scrollDelta, _.options ] );
				if ( _._scrollDelta > 0 ) {
					_._handleScrollDown();
				} else {
					_._handleScrollUp();
				}
			}

			// Reset state variables for next run
			_._scrollTop = scrollTop;
			_._scrollDelta = 0;
		}

		_handleScrollUp() {
			const _ = this;
			
			header.show();
		}

		_handleScrollDown() {
			const _ = this;

			if ( isHome && 'desktop' == media ) {

				if ( _._pastMinY ) {
					header.show();
				} else {
					_._scrollStoppedTimer = setTimeout(function() {
						// Wait for scroll to stop
						header.show();

						if ( noInteractionTimer ) {
							clearTimeout( noInteractionTimer );
							noInteractionTimer = null;
						}
					}, 75);

				}

			} else if ( _._pastMinY ) {
				header.hide();
			}
		}

	};


	// Create instance
	window.ScrollVelocity = new ScrollVelocity(); // jshint ignore:line

	noInteractionTimer = setTimeout(function() {
		header.show();
		noInteractionTimer = null;
	}, 6000);

	$(window).on( 'resize', debounce( () => {
		headerHeight = $siteHeader.outerHeight();
		heroThreshold = $(window).height();

		if ( $sectionMenu.length ) {
			$sectionMenu.css( {
				transform: header.getTransform()
			} );
		}
	}, 150 ) );

	// Show the header when scrolling up at scrollTop == 0
	// Listen for possible scroll methods as 'scroll' event will not trigger
	$(window).on('keydown mousewheel', throttle( (event) => {
		if ( isHome || $(window).scrollTop() !== 0 ) {
			return;
		}

		const ARROW_KEY_UP = 38;

		if ( 'mousewheel' == event.type ) {

			let delta = null;

			if ( event.originalEvent.wheelDelta ) { // will work in most cases
        delta = event.originalEvent.wheelDelta / 60;
	    } else if ( event.originalEvent.detail ) { // fallback for Firefox
        delta = -event.originalEvent.detail / 2;
	    }

	    // Wheel Delta: > 0 == Up, < 0 == Down
	    if ( null !== delta && delta > 0 ) {
				header.show();
	    }

		} else if ( 'keydown' == event.type && ARROW_KEY_UP == event.which ) {
			header.show();
		}
	}, 150 ) );

} )( jQuery, window, document );
