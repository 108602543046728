// URL utility functions

/**
 * Add a query arg to the src attribute on an oEmbed item.
 * This function does not support adding multiple args.
 * @param  {string} key   The query arg name.
 * @param  {string} value The query arg value.
 * @param  {string} url   Optional, The base URL. Defaults to document.location.
 * @return string         The URL with the query arg added.
 */
function addQueryArg( key, value, url = null ) {
	if ( !url ) {
		url = document.location.href;
	}

	// Check for an existing query string
	let queryStringPos = url.indexOf('?');

	if ( queryStringPos == -1 ) {
		return url + `?${key}=${value}`;
	}

	let queryString = url.substr( queryStringPos + 1 ).split('&');
	let queryStringKeys = queryString.map( arg => arg.split('=')[0] );

	// Check for an existing key value
	let keyPosition = queryStringKeys.indexOf( key );

	if ( keyPosition == -1 ) {
		return url + `&${key}=${value}`;
	}

	// At this point we know there is an existing query string containing the key.
	// Replace the existing key's value with the new value.
	queryString[ keyPosition ] = `${key}=${value}`;

	// Rebuild the full URL
	return url.substr( 0, queryStringPos + 1 ) + queryString.join('&');
}

/**
 * A multiple args version of the above function.
 * @param {Object} args An object with { key: value } pairs of args.
 * @param {String} url  The URL with the query args added.
 */
function addQueryArgs( args, url = null ) {
	if ( !url ) {
		url = document.location.href;
	}

	// Iterate args properties
	let keys = Object.keys( args ), len = keys.length;
	for (let i = 0; i < len; i++) {
		url = addQueryArg( keys[i], args[ keys[i] ], url );
	}

	return url;
}
