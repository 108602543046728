( function( $, window, document, undefined ) {

	function checkVis(element) {
	    var top_of_element = element.offset().top;
	    // var bottom_of_element = element.offset().top + element.outerHeight();
	    // var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
	    var top_of_screen = $(window).scrollTop();

	    if ((top_of_element <= top_of_screen)){
	        return true;
	    } else {
	        return false;
	    }
	}

	// const $signup_toggle = $('.sign-up-toggle');
	// const $sections = $('#sections');
	
	// $(window).on('load scroll', function() {

	// 	if ( $sections.length ) {

	// 		$checkvis = checkVis($sections);

	// 		if ( $checkvis && $signup_toggle.hasClass('hide') ) {
	// 			$signup_toggle.removeClass('hide');
	// 		} else if ( !$checkvis && !$signup_toggle.hasClass('hide') ) {
	// 			$signup_toggle.addClass('hide');
	// 		}

	// 	}
	// })

} )( jQuery, window, document );
