// Setup the Featherlight plugin

( function( $, window, document, undefined ) {

	/**
	 * Get elements matching the selector from the current Featherlight instance.	
	 * @param {String} selector A jQuery compatible selector.
	 * @return {jQuery} A jQuery element or set of elements.
	 */
	function getFromCurrent( selector ) {
		const current = $.featherlight.current();

		if ( current ) {
			const $elements = current.$instance.find( selector );

			if ( $elements.length ) {
				return $elements;
			}
		}

		// If here, nothing was found or no current instance exists
		return false;
	}

	function lazyload_content( $container ) {
		let $lazyload = $container.find('[data-lazy]');

		if ( $lazyload.length ) {
			let $lazyload_replace = $($lazyload.data('lazy'));

			$lazyload_replace.imagesLoaded().progress( function(instance, image) {
				if ( image.isLoaded ) {
					$(image.img).removeClass('_not-loaded');
				}
			} );
			$lazyload.replaceWith( $lazyload_replace );
		}
	}

	if ( typeof $.featherlight !== 'undefined' ) {	

		// Change default featherlight selector, favour gallery use case
		$.featherlight.defaults.targetAttr = 'data-featherlight-gallery-content';
		$.featherlight.defaults.closeIcon = '<svg class="svg-icon svg-icon-close" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path fill="#fff" d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>';

		// Don't allow the body to scroll while a container is open
		$.featherlight.defaults.afterOpen = (event) => {
			$('body').addClass('featherlight-open');

			// ScrollVelocity.disable();
			// ViewportScroll.disable();
		};

		$.featherlight.defaults.afterContent = function (event) {
			// Check for lazy loading of content and populate if needed
			let $thumbnail = this.$currentTarget;
			let $thumbnail_lightbox = $thumbnail.next();
			let $content = this.$content;

			lazyload_content( $content );
			lazyload_content( $thumbnail_lightbox );

			let $btn = $content.find('[data-href]');
			if ( $btn.length ) {
				$btn.replaceWith(
					`<a href="${$btn.data('href')}" class="${$btn.attr('class')}" target="_blank">${$btn.html()}</a>`
				);
			}


			// Autoplay videos in lightbox
			let $iframe = getFromCurrent('iframe');

			if ( $iframe.length ) {
				$iframe.get(0).src = addQueryArgs( {
					autoplay: 1,
					rel: 0,
					autopause: 1
				}, $iframe.get(0).src );
			}
		};

		$.featherlight.defaults.beforeClose = (event) => {
			// ViewportScroll.enable();
			// ScrollVelocity.enable();

			$('body').removeClass('featherlight-open');
		};

	}

} )( jQuery, window, document );
