// Apply active state to current section

(function( $ ) {

	const $sections = $('.layout-section');
	const $logo = $('.site-logo');
	const $nav = $('.page-nav__item');
	var $active_nav;

	$(window).on('load scroll', function() {
		$sections.each( function() {
			var top_of_element = $(this).offset().top;
		    var bottom_of_header = $logo.offset().top + $logo.outerHeight();

		    if ( top_of_element <= ( bottom_of_header + 1 ) ) {

		    	$active_nav = $('.page-nav__item.' + $(this).attr('id') );

		    	if ( !$active_nav.hasClass('is-current') ) {
		    		$nav.removeClass('is-current');
		    		$active_nav.addClass('is-current');
		    	}
		    }
		});
	})
	
})( jQuery );