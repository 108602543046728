( function( $, window, document, undefined ) {

	const $logo_lg = $('body.home').find('.hero');
	const $logo_sm = $('.site-logo');

	$(window).on('load scroll resize', throttle( stickyLogo, 150) );

	function stickyLogo() {
		if ( $logo_lg.length ) {
			var top_of_element = $logo_lg.offset().top;
		    var bottom_of_element = ( $logo_lg.offset().top + $logo_lg.outerHeight() ) + 100;
		    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
		    var top_of_screen = $(window).scrollTop();

		    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
		        if ( $logo_sm.hasClass('show') ) {
		        	$logo_sm.removeClass('show');
		        }
		    } else {
		        if ( !$logo_sm.hasClass('show') ) {
		        	$logo_sm.addClass('show');
		        }
		    }
		}
	}
	
} )( jQuery, window, document );