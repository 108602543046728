( function( $, window, document, undefined ) {

	const $map_toggle = $('.js-map-key-toggle');
	const $map_panel = $('.map-panel');

	$map_toggle.on('click', function() {
		if ( $map_panel.hasClass('is-open') ) {
			$map_panel.removeClass('is-open');
		} else {
			$map_panel.addClass('is-open');
		}
	});
	

} )( jQuery, window, document );